<script setup lang="ts">
import useVuelidate from "@vuelidate/core";

const { t } = useI18n();

interface stateStepRules {
  repair: {
    maxvalue: {};
  };
}

const props = defineProps<{
  state: {
    warranty: {
      iswarranty: boolean;
      files: string[];
      retourewarranty: boolean;
      kvwarranty: boolean;
    };
    kv: {
      iskv: boolean;
    };
    repair: {
      isrepair: boolean;
      maxvalue: string;
    };
    repairwithoutvalue: {
      isrepairwithoutvalue: boolean;
    };
  };
  rules: stateStepRules;
}>();

const { state, rules } = toRefs(props);

// Rules
const stateRules = reactive({
  ...rules.value,
});
const v4 = useVuelidate(stateRules, state);

// File Upload
const handleImagesUpdate = (images: string[]) => {
  state.value.warranty.files = images;
};

const handleChange = () => {
  state.value.warranty.iswarranty = false;
  state.value.kv.iskv = false;
  state.value.repair.isrepair = false;
  state.value.repairwithoutvalue.isrepairwithoutvalue = false;
};

// Radio Handler
const updateOptions = (isFirstOptionSelected: boolean) => {
  state.value.warranty.kvwarranty = isFirstOptionSelected;
  state.value.warranty.retourewarranty = !isFirstOptionSelected;
};
</script>

<template>
  <div class="w-full flex flex-col sm:flex-row md:flex-row gap-3 mt-5 sm:mt-0">
    <label
      class="w-full sm:w-1/2 b-1 b-scheppach-primary-700 c-scheppach-primary-700 rd px-3 select-none cursor-pointer"
      :class="[state.warranty.iswarranty ? 'bg-scheppach-primary-100' : '']"
    >
      <div class="flex items-center gap-1">
        <input
          v-model="state.warranty.iswarranty"
          class="w-6 h-6 accent-scheppach-primary-500"
          type="checkbox"
          @click="handleChange"
        />
        <p class="font-500 text-lg p-3 lh-tight">
          {{ t("form.contactForm.guaranteedRepairFromScheppach") }}
        </p>
      </div>
    </label>

    <label
      class="w-full sm:w-1/2 b-1 b-scheppach-primary-700 c-scheppach-primary-700 rd px-3 select-none cursor-pointer"
      :class="[state.kv.iskv ? 'bg-scheppach-primary-100' : '']"
    >
      <div class="flex items-center gap-1">
        <input
          v-model="state.kv.iskv"
          class="w-6 h-6 accent-scheppach-primary-500"
          type="checkbox"
          @click="handleChange"
        />
        <p class="font-500 text-lg p-3 lh-tight">
          {{ t("form.contactForm.estimateOfCostsFromScheppach") }}
        </p>
      </div>
    </label>
  </div>

  <div class="w-full flex flex-col sm:flex-row md:flex-row gap-3 mb-5">
    <label
      class="w-full sm:w-1/2 b-1 b-scheppach-primary-700 c-scheppach-primary-700 rd px-3 select-none cursor-pointer"
      :class="[state.repair.isrepair ? 'bg-scheppach-primary-100' : '']"
    >
      <div class="flex items-center gap-1">
        <input
          v-model="state.repair.isrepair"
          class="w-6 h-6 accent-scheppach-primary-500"
          type="checkbox"
          @click="handleChange"
        />
        <p class="font-500 text-lg p-3 lh-tight">
          {{ t("form.contactForm.immediateRepairWithCapOnCosts") }}
        </p>
      </div>
    </label>

    <label
      class="w-full sm:w-1/2 b-1 b-scheppach-primary-700 c-scheppach-primary-700 rd px-3 select-none cursor-pointer"
      :class="[
        state.repairwithoutvalue.isrepairwithoutvalue
          ? 'bg-scheppach-primary-100'
          : '',
      ]"
    >
      <div class="flex items-center gap-1">
        <input
          v-model="state.repairwithoutvalue.isrepairwithoutvalue"
          type="checkbox"
          class="w-6 h-6 accent-scheppach-primary-500"
          @click="handleChange"
        />
        <p class="font-500 text-lg p-3 lh-tight">
          {{ t("form.contactForm.immediateRepairWithoutCapOnCosts") }}
        </p>
      </div>
    </label>
  </div>

  <!-- Kaufbeleg -->
  <template v-if="state.warranty.iswarranty">
    <!-- Guarantee Checked -->
    <SharedFileUpload
      class="mb-5"
      :base64-data="state.warranty.files"
      @update:base64-data="handleImagesUpdate"
    />
    <div class="b b-scheppach-primary-500 px-3 py-2 rd bg-scheppach-primary-50">
      <p class="c-scheppach-primary-500 font-700 mb-2">
        {{ t("form.contactForm.noWarrantyRadioText") }}
      </p>

      <label class="block">
        <input
          v-model="state.warranty.kvwarranty"
          type="radio"
          name="options"
          :value="true"
          class="accent-scheppach-primary-500 w-4 h-4 mb-3"
          @change="updateOptions(true)"
        />
        <span class="ml-2">
          {{ t("form.contactForm.costEstimate") }}
        </span>
      </label>

      <label class="block">
        <input
          v-model="state.warranty.retourewarranty"
          type="radio"
          name="options"
          :value="true"
          class="accent-scheppach-primary-500 w-4 h-4"
          @change="updateOptions(false)"
        />
        <span class="ml-2">
          {{ t("form.contactForm.returnUnrepairedDevice") }}
        </span>
      </label>
    </div>
  </template>

  <!-- Maximum Amount Checked -->
  <template v-if="state.repair.isrepair">
    <ScheppachTextInput
      v-model="state.repair.maxvalue"
      class="w-full sm:w-75 mt-5"
      :label="t('form.contactForm.maximumAmountInEuro')"
      :required="true"
      :placeholder="t('form.contactForm.maximumAmountInEuroPlaceholder')"
      :input-error="v4.repair.maxvalue?.$error"
      :input-error-message="v4.repair.maxvalue?.$errors[0]?.$message"
      @blur="v4.repair.maxvalue?.$touch()"
    />
  </template>
</template>
